import React, {useContext, useEffect, useRef, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import {MainContext} from '../../context';
import SlideToggle from "react-slide-toggle";
// import { ReactComponent as VolumeIcon } from '../../assets/icons/volume.svg';
import { ReactComponent as ReadIcon } from '../../assets/icons/read.svg';
// import { ReactComponent as ItemIcon } from '../../assets/icons/item.svg';
import { ReactComponent as ArrowTopIcon } from '../../assets/icons/arrow-top.svg';

import RoomListItem from '../listElements/RoomListItem';
import SubRoomListItem from '../listElements/SubRoomListItem';
import SpeechButton from '../SpeechButton';
import PlaceGuide from '../PlaceGuide';
import ImageGallery from '../ImageGallery';
// import Speech from 'react-speech';

const RoomPage = (props) => {
    const {states, handleStates} = useContext(MainContext);
    const [scrollTop, setScrollTop] = useState(window.pageYOffset);
    const history = useHistory();
    let {museumName, museumRoom} = useParams();
    const scrollTarget = useRef();

    useEffect(() => {
        // const getRoom = (obj, i) => {
        //     if(obj.url === museumRoom) {
        //         handleStates('currentRoom', obj);
        //     } else if(obj.items && Array.isArray(obj.items)) {
        //         obj.items.forEach((room_obj, i) => {
        //             getRoom(room_obj, i);
        //         });
        //     }
        // }
        const findCurrentRoom = (item) => {
            item.items.forEach((room, i) => {
                if(room.url === museumRoom) {
                    if(parseInt(room.total_floors) > 0) {
                        room.itemsArr = [...Array(parseInt(room.total_floors)).keys()].map((i) => room.items['floor'+(i+1)].items);
                        room.imagesArr = room.floors_images;
                    } else {
                        room.itemsArr = [room.items];
                        room.imagesArr = [room.image_url]
                    }
                    if(room.place_type !== 'house' && item.items[i+1])
                        room.nextRoom = item.items[i+1];
                    handleStates('currentRoom', room);
                }
            });
        }

        if(!states.currentMuseum || states.currentMuseum.url !== museumName) {
            states.museums.forEach((item) => {
                if(item.url === museumName) {
                    handleStates('currentMuseum', item);
                    if(!states.currentRoom || states.currentRoom.url !== museumRoom)
                        findCurrentRoom(item);
                }
             });
        } else if(!states.currentRoom || states.currentRoom.url !== museumRoom) {
            findCurrentRoom(states.currentMuseum);
        }

        if(document.location.hash !== '' && scrollTarget.current && scrollTarget.current.offsetTop > 0) {
            window.scrollTo(0, scrollTarget.current.offsetTop-180);
        } else if(document.location.hash === '') {
            window.scrollTo(0, 0);
        }

    }, [states, handleStates, museumName, museumRoom]);

    const renderSubRooms = (room) => {
        return (
            <>
            {Array.isArray(room.items) && room.items.map((roomSub, i) =>
                (
                    <div key={i}>
                        <SubRoomListItem room={roomSub} scrollTarget={scrollTarget} />
                        {renderSubRooms(roomSub)}
                    </div>

                )
            )}
            </>
        );
    }

    return (
        <div>
            {(Array.isArray(states.currentRoom.events) && states.currentRoom.events.filter((item) => item.museum_code === states.currentMuseum.museum_code).length > 0) &&
                (<div className="room-event-main" style={{backgroundColor: '#'+states.currentMuseum.light_color}}>
                    {states.currentRoom.events.filter((item) => item.museum_code === states.currentMuseum.museum_code).map((event, i) =>
                        (
                            <div key={i}>
                                <div className="room-event-header">{event.subheading}</div>
                                <div style={{margin: '10px 0px'}}>{event.image_url && 
                                    <ImageGallery images={[event.image_url]} />}
                                </div>
                                <div className="room-event-title" style={{color: '#fff'}}>{event.title}</div>
                                <div className="room-event-description" style={{color: '#fff'}} dangerouslySetInnerHTML={{__html: event.description}}></div>
                            </div>
                        )
                    )}
                </div>)
            }


            {states.currentRoom.itemsArr && states.currentRoom.itemsArr.map((items, ind) => {
                return (<div key={`arrItem-${ind}`}>

                    {states.currentRoom.name &&
                        <div className="room-header" style={{borderLeft: '4px solid ', borderBottom: '4px solid ', borderColor: '#'+states.currentMuseum.light_color}}>
                            <div className="room-header-text">
                                <div>
                                    {states.currentRoom.name}
                                    {parseInt(states.currentRoom.total_floors) > 0 && <div>{states.translators.floor} {ind+1}</div>}
                                </div>
                            </div>
                            <span className="room-header-number room-number" style={{backgroundColor: '#'+states.currentMuseum.light_color}}>{states.currentRoom.place_code}</span>
                            {states.currentRoom.imagesArr[ind] && <img src={states.currentRoom.imagesArr[ind]} alt="" style={{width:'100%',marginBottom:'-4px'}} />}
                        </div>
                    }

                    <div className="museum-text-small" dangerouslySetInnerHTML={{__html: states.currentRoom.small_text}}></div>
                    {states.currentRoom.text ? 
                        (
                        <SlideToggle 
                            collapsed={true} 
                            render={({ toggle, setCollapsibleElement, toggleState }) => (
                                <div className="museum-text-main">
                                    <div style={{display:'flex', justifyContent: 'space-between'}}>
                                        <div className="item-icon-read" style={{flex:1}} onClick={() => {if(toggleState === 'COLLAPSED') setScrollTop(window.pageYOffset); toggle();}}>
                                            {toggleState === 'EXPANDED' ? <ArrowTopIcon fill="#0D3845" fillOpacity="0.5"/> : <ReadIcon fill="#0D3845" fillOpacity="0.5" />}
                                        </div>
                                        {['ru','en','fi'].includes(states.lang) &&
                                        <div className="item-icon-listen" style={{backgroundColor: '#'+states.currentMuseum.light_color}}>
                                            <SpeechButton title={states.currentRoom.title} src={states.currentRoom.audio_url} />
                                        </div>}
                                    </div>
                                    <div className="museum-text" ref={setCollapsibleElement}>
                                        <div dangerouslySetInnerHTML={{__html: states.currentRoom.text}}></div>
                                        <div className="collapse-btn" onClick={() => {window.scrollTo(0, scrollTop); toggle();}}><ArrowTopIcon fill="#0D3845" fillOpacity="1" />{states.translators.hide}</div>
                                    </div>
                                </div>
                            )} />
                        ) : 
                        (<div className="museum-text-main" style={{margin:0}}></div>)
                    }

                    {Array.isArray(items) && items.map((room, i) =>
                            {
                                return ((document.location.hash !== '') ? 
                                (
                                    <div key={i} className="room-list-item-wrap">
                                        <RoomListItem room={room} scrollTarget={scrollTarget} />
                                        {renderSubRooms(room)}
                                    </div>
                                ) : 
                                (

                                    <LazyLoad key={i} height={200}>
                                        <div className="room-list-item-wrap">
                                            <RoomListItem room={room} scrollTarget={scrollTarget} />
                                            {renderSubRooms(room)}
                                        </div>
                                    </LazyLoad>

                                )
                            )
                            }

                    )}

                </div>)
            })}
            <LazyLoad key="placeguide" height={100}>
                <PlaceGuide icon={states.currentRoom.place_icon} text={states.currentRoom.place_guide} />
                {states.currentRoom.nextRoom && <button style={{width: '100%', marginTop:'30px'}} onClick={() => {history.push('/'+states.currentMuseum.url+'/'+states.currentRoom.nextRoom.url);}}>{states.translators.next_room}</button>}
            </LazyLoad>
        </div>
    );
}

export default RoomPage;