import React, {useContext, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import {MainContext} from '../../context';
import axios from 'axios';
import {API_FEEDBACK_URL, BASENAME} from '../../constants';
import Header from '../../components/Header';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as StarFilledIcon } from '../../assets/icons/star-filled.svg';
import { useAlert } from 'react-alert';

const FeedbackPage = (props) => {
    const alert = useAlert();
    const {states, handleStates} = useContext(MainContext);
    let {museumName} = useParams();

    const sendFeedback = () => {
        if(!states.feedbackData.rate || !states.feedbackData.age) {
            alert.show(states.translators.feedback_fill_all_data, {closeCopy: states.translators.alert_close, type: 'success'});
            return false;
        }

        axios.post(API_FEEDBACK_URL + '?lang='+states.lang, {
            sender_rate: states.feedbackData.rate, 
            sender_message: states.feedbackData.message,
            sender_age: states.feedbackData.age,
            museum: states.currentMuseum.museum_code
        })
        .then(res => {
            alert.show(states.translators.feedback_send_success, {closeCopy: states.translators.alert_close, type: 'success'});
            handleStates('feedbackData', {message: '', rate: '', age: ''});
        });
    }

    useEffect(() => {
        if(!states.currentMuseum) {
            states.museums.forEach((item) => {
                if(item.url === museumName) {
                    handleStates('currentMuseum', item);
                }
             });
        }
    }, [states, handleStates, museumName]);

    return (
        <Dialog 
            fullScreen open={true}
            PaperProps={{
                style: {
                    backgroundColor: '#f6f6f6',
                    opacity: 1,
                },
            }}
        >
            <Helmet>
                <style>
                    {`
                        .MuiPaper-root, .header-main {background: url("/bg/white.svg"); background-attachment: fixed; background-color: rgba(246, 246, 246, 1);}
                    `}
                </style>
            </Helmet>
            <div className="main">
                <Header page="feedback"/>
                <div className="feedback-body">
                    <h2 className="feedback-header">{states.translators.feedback_header}</h2>
                    <div className="feedback-text">{states.translators.feedback_description}
                    </div>
                    <h2 className="feedback-header">{states.translators.feedback_rate} <sup style={{color:'red'}}>*</sup></h2>
                    <div className="feedback-stars">
                        {[1,2,3,4,5].map(i => {
                            if(i <= states.feedbackData.rate)
                                return (<StarFilledIcon key={`rate-${i}`} onClick={() => handleStates('feedbackData', {...states.feedbackData, 'rate': i})} fill="#0D3845" stroke="" />);
                            else
                                return (<StarIcon key={`rate-${i}`} onClick={() => handleStates('feedbackData', {...states.feedbackData, 'rate': i})} fill="#0D3845" stroke="" />);
                        })}
                        {/* 
                        <button><StarFilledIcon fill="#0D3845" stroke="" /></button>
                        <button><StarFilledIcon fill="#0D3845" stroke="" /></button>
                        <button><StarFilledIcon fill="#0D3845" stroke="" /></button>
                        <button><StarIcon fill="#0D3845" stroke="" /></button>
                        <button><StarIcon fill="#0D3845" stroke="" /></button> */}

                    </div>
                    <h2 className="feedback-header">{states.translators.feedback_age} <sup style={{color:'red'}}>*</sup></h2>
                    <div className="feedback-ages">
                        {[['... - 16', '... - 16'], ['17 - 25', '17 - 25'], ['26 - 35', '26 - 35'], ['36 - 45', '36 - 45'], ['46 - 65', '46 - 65'], ['65 - ...', '65 - ...']].map((item, i) =>
                            (<button key={`age-${i}`} onClick={() => handleStates('feedbackData', {...states.feedbackData, 'age': item[1]})} style={{background: (item[1] === states.feedbackData.age) ? 'rgba(13, 56, 69, 0.25)' : ''}}>{item[0]}</button>)
                        )}
                    </div>
                    <h2 className="feedback-header">{states.translators.feedback_comment}</h2>
                    <div className="feedback-textarea">
                        <textarea onChange={(e) => handleStates('feedbackData', {...states.feedbackData, 'message': e.target.value})} value={states.feedbackData.message}></textarea>
                    </div>
                    <button onClick={() => sendFeedback()} className="centered" style={{width:'70%'}}>{states.translators.feedback_send}</button>
                </div>
            </div>
        </Dialog>
    );
}

export default FeedbackPage;