import React, {useContext} from 'react';
// import {Transition, animated} from "react-spring/renderprops";
import {Helmet} from 'react-helmet';
import {Switch, Route, useHistory, useLocation, withRouter} from 'react-router-dom';
import {BASENAME} from '../../constants';
import {MainContext} from '../../context';
import BeatLoader from "react-spinners/BeatLoader";
import SettingsPage from '../../components/pages/SettingsPage';
import MuseumList from '../../components/pages/MuseumList';
import MuseumRooms from '../../components/pages/MuseumRooms';
import RoomPage from '../../components/pages/RoomPage';
import EventPage from '../../components/pages/EventPage';
import SearchPage from '../../components/pages/SearchPage';
import FeedbackPage from '../../components/pages/FeedbackPage';
import ErrorPage from '../../components/pages/ErrorPage';
import Header from '../../components/Header';
import BottomNav from '../../components/BottomNav';

import { TransitionGroup, CSSTransition } from "react-transition-group";

const MainPage = (props) => {
    const {states, handleStates} = useContext(MainContext);
    const history = useHistory();
    const location = useLocation();

    // const MuseumList = React.lazy(() => import('../../components/pages/MuseumList'));
    // const MuseumRooms = React.lazy(() => import('../../components/pages/MuseumRooms'));
    // const RoomPage = React.lazy(() => import('../../components/pages/RoomPage'));
    // const EventPage = React.lazy(() => import('../../components/pages/EventPage'));

    return (
        <div>
            <Helmet>
                <title>{states.translators.meta_title}</title>
                <style>
                    {`
                        body, .header-main {background: #e5e5e5 url("/bg/white.svg"); background-attachment: fixed;}
                    `}
                </style>
            </Helmet>
            <div className="main">
                <Header/>
                {
                    (states.contentLoaded) ? 
                    (<TransitionGroup className="transition-group">
                        <CSSTransition
                            key={location.key}
                            timeout={{ enter: 500, exit: 0 }}
                            classNames={'fade'}
                        >
                            <section className="route-section">
                        {(states.errorPage && location.pathname !== '/settings') || (states.errorLangPage && location.pathname === '/settings') ? 
                            (<ErrorPage/>) : 
                            (
                                <Switch>
                                    <Route path="/settings">
                                        <SettingsPage />
                                    </Route>
                                    <Route path="/:museumName/search">
                                    <SearchPage/>
                                    </Route>
                                    <Route path="/:museumName/events">
                                        <EventPage/>
                                    </Route>
                                    <Route path="/:museumName/feedback">
                                        <FeedbackPage/>
                                    </Route>
                                    <Route path="/:museumName/:museumRoom">
                                        <RoomPage/>
                                    </Route>
                                    <Route path="/:museumName">
                                        <MuseumRooms/>
                                    </Route>
                                    <Route path="/">
                                        <MuseumList/>
                                    </Route>
                                </Switch>
                            )
                        }
                            </section>
                        </CSSTransition>
                    </TransitionGroup>) :
                    (<div id="loader"><BeatLoader/></div>)
                }
            </div>
            {states.currentMuseum && <BottomNav/>}
        </div>
    );
}

export default withRouter(MainPage);