import React, {useContext} from 'react';
import {MainContext} from '../context';
import { ReactComponent as ArrowRightIcon } from '../assets/icons/arrow-right.svg';

const PlaceCode = (props) => {
    const {states} = useContext(MainContext);
    const arr = props.text.split('-');
    return (
        <div className="place-code" style={(parseInt(arr[0]) >= 10 && parseInt(arr[1]) >= 10 && props.from === 'square') ? {fontSize: '26px'} : {}}>
            {arr.map((item, i) => {
                return (
                    <span key={`${i}`}>
                        <span>{item}</span>
                        {i+1 < arr.length && <ArrowRightIcon key={i} fill={`#${states.currentMuseum.light_color}`} fillOpacity={0.9} stroke="" />}
                    </span>
                );
            })}
        </div>
    )
}

export default PlaceCode;