import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {MainContext} from '../../context';
import { ReactComponent as ItemIcon } from '../../assets/icons/item.svg';

const MuseumList = (props) => {
    const {states, handleStates} = useContext(MainContext);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        handleStates('currentMuseum', false);
    }, []);

    return (
        <div>
            {states.museums.map((item, k) => {
                return (
                    <div className="museum-list-item link" style={{borderColor: '#'+item.light_color}} key={k} onClick={() => {history.push('/'+item.url);}} >
                    {item.banner_url ? <img src={item.banner_url} alt="" /> : <div style={{width: '100px', minHeight: '100px'}}></div>}
                        <span className="museum-list-item-header">{item.name}</span>
                        <div className="item-icon" style={{backgroundColor: '#'+item.light_color}}><ItemIcon fill={'#'+item.light_color} stroke="white" /></div>
                    </div>
                );
            })}
        </div>
    );
}

export default MuseumList;