import React, {useContext, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useParams, useHistory} from 'react-router-dom';
import {MainContext} from '../../context';
import axios from 'axios';
import {API_SEARCH_URL, BASENAME} from '../../constants';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as BackkeyIcon } from '../../assets/icons/backkey.svg';
import { useAlert } from 'react-alert';


const SearchPage = (props) => {
    const alert = useAlert();
    const history = useHistory();
    const {states, handleStates} = useContext(MainContext);
    let {museumName} = useParams();

    const doSearch = () => {
        handleStates('contentLoaded', false);
        axios.get(API_SEARCH_URL + '?lang='+states.lang + '&museum=' + states.currentMuseum.museum_code + '&search_txt=' + states.searchResult.map((item) => item[0] === 'numb' ? item[1] : '-').join(''))
        .then(res => {
            handleStates('contentLoaded', true);
            if(res.data.length > 0) {
                handleStates('searchResult', []);
                history.push('/'+states.currentMuseum.url+'/'+res.data[0].parent_url+'#'+res.data[0].place_code);
            } else {
                alert.show(states.translators.search_not_found, {closeCopy: states.translators.alert_close, type: 'error'});
            }
        });
    }

    const appendToSearch = (type, param) => {
        
        let searchResult = states.searchResult;

        if(type === 'del')
            searchResult.pop();
        else if(searchResult.length < 10)
            searchResult.push([type, param]);
        // if(type === 'numb') {
        //     searchResult.push([type, param]);
        // } else if(type === 'arr') {
        //     searchResult.push(<ArrowRightIcon fill="#333333" fillOpacity={0.9} stroke="" />);
        // }
        return searchResult;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(!states.currentMuseum) {
            states.museums.forEach((item) => {
                if(item.url === museumName) {
                    handleStates('currentMuseum', item);
                }
             });
        }
    }, [states, handleStates, museumName]);

    useEffect(() => {
        handleStates('searchResult', []);
    }, []);

    return (
        <div>
            <Helmet>
                <style>
                    {`
                        body, .header-main {background: url("/bg/white.svg"); background-attachment: fixed; background-color: rgba(246, 246, 246, 1);}
                    `}
                </style>
            </Helmet>
            <div className="search-example">
                <span>{states.translators.example}</span>
                <span>1</span>
                <ArrowRightIcon fill="#0D3845" stroke="" />
                <span>2</span>
                <ArrowRightIcon fill="#0D3845" stroke="" />
                <span>10</span>
            </div>
            <div className="search-result">
                {states.searchResult.map((item, i) => {
                    if(item[0] === 'numb')
                        return (<span key={i}>{item[1]}</span>)
                    else
                        return (<ArrowRightIcon key={i} fill="#333333" fillOpacity={0.9} stroke="" />)
                })}
                {/* <span>1</span>
                <ArrowRightIcon fill="#333333" fillOpacity={0.9} stroke="" />
                <span>4</span>
                <ArrowRightIcon fill="#333333" fillOpacity={0.9} stroke="" />
                <span>10</span> */}
            </div>
            <div className="search-keypad">
                <div className="keypad-row">
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 1))}>1</button>
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 2))}>2</button>
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 3))}>3</button>
                </div>
                  <div className="keypad-row">
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 4))}>4</button>
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 5))}>5</button>
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 6))}>6</button>
                </div>
                <div className="keypad-row">
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 7))}>7</button>
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 8))}>8</button>
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 9))}>9</button>
                </div>      
                <div className="keypad-row">
                    <button className="next key-btn" onClick={() => handleStates('searchResult', appendToSearch('arr'))}><ArrowRightIcon fill="#333333" fillOpacity={0.9} stroke="" /></button>
                    <button className="number key-btn" onClick={() => handleStates('searchResult', appendToSearch('numb', 0))}>0</button>
                    <button className="back key-btn" onClick={() => handleStates('searchResult', appendToSearch('del'))}><BackkeyIcon fill="#333333" fillOpacity={0.9} stroke="" /></button>
                </div>           
            </div>
            <button className="search-btn centered" onClick={() => doSearch()} disabled={!(states.searchResult.length > 0)}>{states.translators.search}</button>
        </div>
    );
}

export default SearchPage;