import React, {useContext} from 'react';
import {Helmet} from 'react-helmet';
import {BASENAME} from '../../constants';
import {MainContext} from '../../context';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';

const LanguagePage = (props) => {
    const {states, handleStates} = useContext(MainContext);

    return (
        <div>
            <Helmet>
                <title>Virumaa Muuseumid</title>
                <style>
                    {`
                        body {background: url("/bg/dark.svg"); background-color: #0D3845; background-attachment: fixed; padding: 20px 20px 0px 20px;}
                    `}
                </style>
            </Helmet>
             <LogoIcon fill='white' stroke='' />
            <ul className="languages-first">
            {Object.keys(states.languages).map(k => 
                (<li key={k}><span onClick={() => handleStates('lang', k)}>{states.languages[k]}</span></li>)
            )}
            </ul>
        </div>
    );
}

export default LanguagePage;