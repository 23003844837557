import React, {useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {MainContext} from '../context';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as RoomsIcon } from '../assets/icons/rooms.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';


const BottomNav = (props) => {

    const {states, handleStates} = useContext(MainContext);
    const location = useLocation();
    const history = useHistory();

    const bottomLeftClickHandler = () => {
        handleStates('backFromRooms', true); 
        history.push('/'+states.currentMuseum.url);
    }
    const bottomCenterClickHandler = () => {
        handleStates('backPage', location.pathname); 
        history.push('/'+states.currentMuseum.url+'/search');
    }
    const bottomRightClickHandler = () => {
        history.push('/'+states.currentMuseum.url+'/events')
    }

    return (

        <div className="bottom-nav">
            <div className={`bottom-nav-left${'/'+states.currentMuseum.url === location.pathname ? " active": ""}`} onClick={bottomLeftClickHandler}><HomeIcon fill='#11495A' stroke='' /></div>
            <div className="bottom-nav-center-icon" onClick={bottomCenterClickHandler} style={{backgroundColor: '#'+states.currentMuseum.dark_color}}> <RoomsIcon label=""fill='white' stroke='' /> </div>
            <div className={`bottom-nav-right${'/'+states.currentMuseum.url+'/events' === location.pathname ? ' active' : ''}`}  onClick={bottomRightClickHandler} style={'/'+states.currentMuseum.url+'/events' === location.pathname ? {} : {}}><InfoIcon fill='#11495A' stroke='' /></div>
        </div>

    );
}

export default BottomNav;