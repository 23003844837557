import React, {useContext, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {MainContext} from '../../context';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import ImageGallery from '../ImageGallery';
import PlaceGuide from '../PlaceGuide';

const EventPage = (props) => {
    const {states, handleStates} = useContext(MainContext);
    const history = useHistory();
    let {museumName} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        if(!states.currentMuseum) {
            states.museums.forEach((item) => {
                if(item.url === museumName) {
                    handleStates('currentMuseum', item);
                }
             });
        }
    }, [states, handleStates, museumName]);

    return (
        <div>
            <div className="events-header">
                <StarIcon fill="#0D3845" fillOpacity="0.5" stroke="" />
                <div className="events-header-text">{states.currentMuseum.name} {states.translators.event_programm}</div>
            </div>

            {(states.currentMuseum && Array.isArray(states.currentMuseum.events) && states.currentMuseum.events.length > 0) ? states.currentMuseum.events.map((event, i) =>
                (
                    <div className="event-list-item link" style={{borderColor: '#'+states.currentMuseum.light_color}} key={i} >
                        <div className="event-list-item-dates">{event.dates}</div>
                        <div className="event-list-item-header">{event.title}</div>
                        {/* <div className="event-list-item-description" dangerouslySetInnerHTML={{__html: event.description}}></div> */}
                        <div className="event-list-item-description" dangerouslySetInnerHTML={{__html: event.description}}></div>
                        <div className="">{event.image_url && 
                            <ImageGallery images={[event.image_url]} />}
                        </div>
                    </div>
                )
            ) : (<PlaceGuide icon="help-attention" text={states.translators.events_not_found} />)
            }

            {/* <button onClick={() => {handleStates('backPage', location.pathname); history.push('/'+states.currentMuseum.url+'/feedback');}}>Feedback</button> */}

        </div>
    );
}

export default EventPage;