import React, {useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {MainContext} from '../context';
import { ReactComponent as LogoIcon } from '../assets/icons/logo.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as XIcon } from '../assets/icons/x.svg';

const Header = (props) => {
    const {states, handleStates} = useContext(MainContext);
    const location = useLocation();
    const history = useHistory();

    let headerTitle = states.translators.choose_museum;
    let backPage = states.backPage;

    if(states.currentMuseum)
        headerTitle = states.currentMuseum.name;

    if(props.page === 'feedback') {
        headerTitle = states.translators.feedback;
    } else if(location.pathname === '/settings') {
        headerTitle = states.translators.settings;
        backPage = states.backPageSettings;
    }

    return (
        <div className="header-main">
            <header>
            <LogoIcon className="link" fill="#333333" stroke='' onClick={() => {handleStates('currentMuseum', false); handleStates('currentRoom', false); history.push('/');}} />
            {
                location.pathname === '/settings' || location.pathname.substr(location.pathname.length - 7) === '/search' || props.page === 'feedback' 
                ? 
                    <XIcon className="link" fill="#333333" fillOpacity={0.5} stroke='' onClick={() => history.push(backPage)} /> 
                : 
                    <SettingsIcon className="link" fill="#333333" fillOpacity={0.5} stroke='' onClick={() => {handleStates('backPageSettings', location.pathname); history.push('/settings')}} />
            }
            </header>
            <div className="museum-list-header">{headerTitle}</div>
            <div className="museum-list-header-underline"></div>
        </div>
    );
}

export default Header;
