import React from 'react';
import PlaceGuide from '../PlaceGuide';

const ErrorPage = (props) => {

    return (
        <div>
            <PlaceGuide icon="help-attention" text="Vabandage, proovige uuesti." />
        </div>
    );
}

export default ErrorPage;