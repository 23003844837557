import React from 'react';
import { ReactComponent as HelpTopIcon } from '../assets/icons/help-top.svg';
import { ReactComponent as HelpBottomIcon } from '../assets/icons/help-bottom.svg';
import { ReactComponent as HelpRightIcon } from '../assets/icons/help-right.svg';
import { ReactComponent as HelpLeftIcon } from '../assets/icons/help-left.svg';
import { ReactComponent as HelpAttentionIcon } from '../assets/icons/help-attention.svg';

const PlaceGiude = (props) => {
    if(!props.icon || !props.text) return (<></>);
    const fill = '#11495A';
    return (
        <div className="room-help-block" style={props.style && props.style}>
            <div className="room-help-block-icon">
            {
                {
                    'help-top': <HelpTopIcon fill={fill} />,
                    'help-bottom': <HelpBottomIcon fill={fill} />,
                    'help-right': <HelpRightIcon fill={fill} />,
                    'help-left': <HelpLeftIcon fill={fill} />,
                    'help-attention': <HelpAttentionIcon fill={fill} />
                }[props.icon]           
            }
            </div>
            <span>{props.text}</span>
        </div>
    )
}

export default PlaceGiude;