import React, {useState} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 


const ImageGallery = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const images = props.images;
    return images.length > 0 ? (
        <>
            <img onClick={() => {setPhotoIndex(0); setIsOpen(true)}} width="100%" src={images[0]} alt="" />
            {images.length > 1 && <div style={{overflow:'scroll', whiteSpace: 'nowrap'}}>
                {images.map((img, i) =>  <img key={`img-${i}-${img}`} onClick={() => {setPhotoIndex(i); setIsOpen(true)}} style={{height:'60px', marginRight: '10px'}} src={img} alt="" />)}
            </div>}
            {isOpen && (
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex(prevState => {
                        return (prevState + images.length - 1) % images.length;
                    })
                }
                onMoveNextRequest={() =>
                    setPhotoIndex(prevState => {
                        return (prevState + 1) % images.length;
                    })
                }
            />
            )}
        </>
    ) : (<></>);
}

export default ImageGallery;