import React, {useContext, useEffect, useState} from 'react';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {MainContext} from '../../context';
import SlideToggle from "react-slide-toggle";
import { ReactComponent as ItemIcon } from '../../assets/icons/item.svg';
import { ReactComponent as ReadIcon } from '../../assets/icons/read.svg';
import { ReactComponent as ArrowTopIcon } from '../../assets/icons/arrow-top.svg';
import SpeechButton from '../SpeechButton';
import PlaceGuide from '../PlaceGuide';
import ImageGallery from '../ImageGallery';

const MuseumRooms = (props) => {
    const {states, handleStates} = useContext(MainContext);
    const [scrollTop, setScrollTop] = useState(window.pageYOffset);
    const history = useHistory();
    const location = useLocation();
    let {museumName} = useParams();

    useEffect(() => {
        if(states.backFromRooms && states.backScrollTop) {
            window.scrollTo(0, states.backScrollTop);
            handleStates('backFromRooms', false);
        }
        if(!states.currentMuseum) {
            states.museums.forEach((item) => {
                if(item.url === museumName) {
                    handleStates('currentMuseum', item);
                }
             });
        }
    }, [handleStates, museumName, states]);

    return (
        <div>
            {states.currentMuseum.image_url && 
                <ImageGallery images={[states.currentMuseum.image_url]} />
            }
            <div className="museum-text-small" dangerouslySetInnerHTML={{__html: states.currentMuseum.small_text}}></div>
            {states.currentMuseum.text && 
                <SlideToggle collapsed={true}>
                    {({ toggle, setCollapsibleElement, toggleState }) =>
                        (
                        <div className="museum-text-main">
                            <div style={{display:'flex', justifyContent: 'space-between'}}>
                                <div className="item-icon-read" style={{flex:1}} onClick={() => {if(toggleState === 'COLLAPSED') setScrollTop(window.pageYOffset); toggle();}}>
                                    {toggleState === 'EXPANDED' ? <ArrowTopIcon fill="#0D3845" fillOpacity="0.5" /> : <ReadIcon fill="#0D3845" fillOpacity="0.5" />}
                                </div>
                                {['ru','en','fi'].includes(states.lang) &&
                                <div className="item-icon-listen" style={{backgroundColor: '#'+states.currentMuseum.light_color}}>
                                    <SpeechButton title={states.currentMuseum.name} src={states.currentMuseum.audio_url} />
                                </div>}
                            </div>
                            <div className="museum-text" ref={setCollapsibleElement}>
                                <div dangerouslySetInnerHTML={{__html: states.currentMuseum.text}}></div>
                                <div className="collapse-btn" onClick={() => {window.scrollTo(0, scrollTop); toggle();}}><ArrowTopIcon fill="#0D3845" fillOpacity="1" /> {states.translators.hide}</div>
                            </div>
                        </div>
                    )}
                </SlideToggle>
            }

            {states.currentMuseum && states.currentMuseum.items.map((room, i) =>
                    (
                        <div key={i}>
                            <div className="museum-list-item link" style={{borderColor: '#'+states.currentMuseum.light_color}} onClick={() => {handleStates('backScrollTop', window.pageYOffset); history.push('/'+states.currentMuseum.url+'/'+room.url);}} >
                                <div className="museum-rooms-number" style={{backgroundColor: '#'+states.currentMuseum.light_color}}>{room.place_code}</div>
                                <span className="museum-list-item-header">{room.name}</span>
                                <div className="item-icon"><ItemIcon fill="white" stroke={'#'+states.currentMuseum.light_color} /></div>
                            </div>
                            <PlaceGuide icon={room.place_icon} text={room.place_guide} />
                        </div>
                    )
            )}


            <button className="centered"  style={{marginTop: '50px', width: '75%'}} onClick={() => {handleStates('backPage', location.pathname); history.push('/'+states.currentMuseum.url+'/feedback');}}>{states.translators.feedback_btn}</button>

        </div>
    );
}

export default MuseumRooms;