import React, {useContext} from 'react';
// import {MainContext} from '../../context';

const SpeechButton = (props) => {
    // const {states} = useContext(MainContext);

    return (
        <iframe title={props.title} frameBorder="0" scrolling="no" style={{width: 23, height: 19, overflow: 'hidden'}} src={props.src}/>
    );
}

export default SpeechButton;