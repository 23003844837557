// import { ReactComponent as StarIcon } from './assets/icons/star.svg';
// import { ReactComponent as XIcon } from './assets/icons/x.svg';
// import { ReactComponent as VolumeIcon } from './assets/icons/volume.svg';
// import { ReactComponent as LogoIcon } from './assets/icons/logo.svg';
// import { ReactComponent as SettingsIcon } from './assets/icons/settings.svg';
// import { ReactComponent as ArrowTopIcon } from './assets/icons/arrow-top.svg';
// import { ReactComponent as InfoIcon } from './assets/icons/info.svg';
// import { ReactComponent as HomeIcon } from './assets/icons/home.svg';
// import { ReactComponent as RoomsIcon } from './assets/icons/rooms.svg';
// import { ReactComponent as ItemIcon } from './assets/icons/item.svg';
// import { ReactComponent as ReadIcon } from './assets/icons/read.svg';
// import { ReactComponent as CalendarIcon } from './assets/icons/calendar.svg';
// import { ReactComponent as StairIcon } from './assets/icons/stair.svg';
// import './App.css';

import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import GoogleFontLoader from 'react-google-font-loader';
import {MainContext} from './context';
import axios from 'axios';
import {API_TRANSLATIONS_URL,API_LANGS_URL,API_URL, BASENAME} from './constants';
import LanguagePage from './components/pages/LanguagePage';
import MainPage from './components/pages/MainPage';
import ReactGA from 'react-ga';

ReactGA.initialize('G-1W2M1PHYFC');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {

  const [states, setStates] = useState({
    lang: localStorage.getItem('lang') || '',
    languages: {},
    museums: [],
    museumRooms: [],
    backPage: '',
    backPageSettings: '',
    currentMuseum: false,
    currentRoom: false,
    searchResult: [],
    feedbackData: [],
    contentLoaded: false,
    backScrollTop: 0,
    backFromRooms: false,
    translators: {},
    errorLangPage: false,
    errorPage: false,
  });

  const handleStates = (k, v = false) => {
    if(['lang'].includes(k)) {
      localStorage.setItem(k, v);
    }
    if(['languages'].includes(k)) {
      localStorage.setItem(k, JSON.stringify(v));
    }
    setStates(prevState => {
      const newState = {...prevState};
      newState[k] = v;
      return newState;
    });
  }

  useEffect(() => {
      axios.get(API_LANGS_URL)
      .then(res => {
        let languages = {}
        res.data.map((item) => languages = {...languages, [item.lang]: item.title}   );
        handleStates('languages', languages);
        handleStates('errorLangPage', false);
      })
      .catch(e => {
        handleStates('contentLoaded', true);
        handleStates('errorLangPage', true);
      });
  }, []);

  useEffect(() => {
    if(states.lang) {
      // get and handle tranlator here
      // after get museum data
      axios.get(API_TRANSLATIONS_URL + '?lang='+states.lang)
      .then(res => {
        handleStates('translators', res.data);
        axios.get(API_URL + '?lang='+states.lang)
        .then(res => {
          if(typeof res.data === 'object') {
            handleStates('museums', res.data);
            handleStates('contentLoaded', true);
            handleStates('errorPage', false);
          } else {
            handleStates('contentLoaded', true);
            handleStates('errorPage', true);
          }
        })
        .catch(e => {
          handleStates('contentLoaded', true);
          handleStates('errorPage', true);
        });

      })
      .catch(e => {
        handleStates('contentLoaded', true);
        handleStates('errorPage', true);
      });
    }
  }, [states.lang]);

  return (
    <Router basename={BASENAME}>
      <GoogleFontLoader
            fonts={[
              {
                font: 'Roboto Condensed',
                weights: [700],
              },
              {
                font: 'Roboto',
                weights: [400, 500, 700, 900],
              },
            ]}
            subsets={['cyrillic-ext', 'greek']}
          />
      <MainContext.Provider value={{states, handleStates}}>
        <div className="App" >
          {/* <LanguagePage/> */}
          {!states.lang ? <LanguagePage/> : <MainPage/>}
        </div>
      </MainContext.Provider>
    </Router>
  );
}
