import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { MainContext } from '../../context';
import { ReactComponent as SphereIcon } from '../../assets/icons/sphere.svg';
import { ReactComponent as PointIcon } from '../../assets/icons/point.svg';
import { ReactComponent as ItemIcon } from '../../assets/icons/item.svg';

const SettingsPage = (props) => {
    const { states, handleStates } = useContext(MainContext);
    const history = useHistory();
    const location = useLocation();
    const handleChooseMuseum = (item) => {
        handleStates('backScrollTop', -1);
        handleStates('backFromRooms', true);
        handleStates('currentMuseum', item);
        history.push('/' + item.url);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Helmet>
                <style>
                    {`
                        body, .header-main {background: url("/bg/white.svg"); background-attachment: fixed; background-color: rgba(246, 246, 246, 1);}
                    `}
                </style>
            </Helmet>
            {states.currentMuseum && <button className="centered" style={{ width: '100%', marginBottom: '40px' }} onClick={() => { handleStates('backPage', location.pathname); history.push('/' + states.currentMuseum.url + '/feedback'); }}>{states.translators.feedback_btn}</button>}
            <div className="settings-header">
                <SphereIcon fill="#0D3845" fillOpacity="0.5" stroke="" />
                <div className="settings-header-text">{states.translators.settings_choose_language}</div>
            </div>
            <ul className="languages">
                {Object.keys(states.languages).map(k =>
                    (<li key={k}><span onClick={() => {if(k === states.lang) {history.push('/'); return;} handleStates('contentLoaded', false); handleStates('currentMuseum', false); handleStates('currentRoom', false); handleStates('lang', k); history.push('/'); }} style={states.lang === k ? { fontWeight: 'bold' } : {}}>{states.languages[k]}</span></li>)
                )}
            </ul>
            <div className="settings-header">
                <PointIcon fill="#0D3845" fillOpacity="0.5" stroke="" />
                <div className="settings-header-text">{states.translators.settings_choose_museum}</div>
            </div>
            <div>
                {states.museums.map((item, k) => {
                    return (!states.currentMuseum || item.url !== states.currentMuseum.url) ? (
                        <div className="museum-list-item link" style={{ borderColor: '#' + item.light_color }} key={k} onClick={() => { handleChooseMuseum(item) }} >
                            {item.banner_url ? <img src={item.banner_url} alt="" /> : <div style={{ width: '100px', minHeight: '100px' }}></div>}
                            <span className="museum-list-item-header">{item.name}</span>
                            <div className="item-icon" style={{ backgroundColor: '#' + item.light_color }}><ItemIcon fill={'#' + item.light_color} stroke="white" /></div>
                        </div>
                    ) : ''
                }
                )}
            </div>
            <div className="settings-tickets-btn">
                <button onClick={() => window.open(states.translators.tickets_link, 'tickets')}>{states.translators.tickets_btn}</button>
            </div>
        </div>
    );
}

export default SettingsPage;